import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6edabe8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "l-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.loadingSurvey)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        "spinner-color": "lavender-700"
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!_ctx.loadingSurvey)
          ? (_openBlock(), _createBlock(_component_HeadingComponent, {
              key: 0,
              title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
              "heading-icon": "icon-id_card",
              description: _ctx.$t('personData.description'),
              tabs: _ctx.tabsMenu
            }, null, 8, ["title", "description", "tabs"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_view)
        ])
      ], 64))
}